/* ==========================================================
 * [custom.js]
 * Project:			wip boilerplate
 * Copyright:		2015 Wip Italia S.r.l.
 * Author URI:		http://www.wipitalia.it
 * ========================================================== */

var wipwrp = window.wipwrp || {};

wipwrp.container = (function($,mzr) {

	var pageType;
	var self = {};


	self.init = function(){
		// wipwrp.utils.notification();
		// take a look also to notify(tgtJQObj, type, content);
		$('html').removeClass('no-document-ready').addClass('document-ready');
		_switchPage();
		wipwrp.common.init();
	};


	function _switchPage(){
		// Define a pageType depending on
		// wipwrp.pageType or DOM elements
		switch(pageType) {
			case 'mypage':
				//wipwrp.mypage.init();
			break;
		}
	};


	//  return public methods
	return self;



})(jQuery,Modernizr);





/* ------------------------------------------------------------
	DEFAULT PAGE CLASS SINTAX (better to have a new file)
------------------------------------------------------------ */

wipwrp.mypage = (function($,mzr) {

	var self = {}

	
	self.metodoPubblico= function(){

	}

	function metodoPrivato(){

	}
	
	//  return public methods
	return self;

})(jQuery,Modernizr);

