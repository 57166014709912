var wipwrp = window.wipwrp || {};

wipwrp.utils = (function($,mzr,doc,win){


	/* ----------------- private vars ---------------*/
	
	var	self				=	{};

	/* ----------------- public methods ---------------*/

	self.getQueryVariable = function(variable){
       var query = window.location.search.substring(1);
       var vars = query.split("&");
       for (var i=0;i<vars.length;i++) {
               var pair = vars[i].split("=");
               if(pair[0] == variable){return pair[1];}
       }
       return(false);
	}


	self.notification = function(autoRemoveOn){
		if(autoRemoveOn==null)autoRemoveOn=false;
		//wdbg.log('wipwrp.utils: notification: autoRemoveOn: '+autoRemoveOn);
		if(autoRemoveOn==false){
			self.removeButton('body','.notification',".close");
		}else{
			if ($('.notification.auto-remove').length > 0){
				setTimeout(function(){
					$('.notification.auto-remove .close').trigger('click')
				},2000);
			}
		}
	} // notification();
	
	self.removeButton = function(area,container,btn){
		//wdbg.log('removeButton: area: "'+area+'" - container: "'+container+'"');
		$(area).on('click',container+' '+btn,function(){
			$(this).parents(container).remove();
			return false;
		});
	} // removeButton();

	
	self.notify = function(tgtJQObj, type, content){
		var $closer = $('<a/>').addClass('close').attr('href','javascript:void(0)').html('x');
		var n = $('<div/>').attr('class','notification '+type).html(content).prepend($closer);
		tgtJQObj.prepend(n);

		$closer.on('click', function(){
			$(this).parent().remove();
		});
		$("html, body").animate({scrollTop:$(n.offset().top)},200);
	} // notify();

	self.browserVendor = function(){
		//http://lea.verou.me/2009/02/find-the-vendor-prefix-of-the-current-browser/
		var someScript = doc.getElementsByTagName('script')[0];
		var regex = /^(Moz|Webkit|Khtml|O|ms|Icab)(?=[A-Z])/;

		for(var prop in someScript.style){

			if(regex.test(prop)){
				// test is faster than match, so it's better to perform
				// that on the lot and match only when necessary
				return prop.match(regex)[0];
			}
		}
		// Nothing found so far? Webkit does not enumerate over the CSS properties of the style object.
		// However (prop in style) returns the correct value, so we'll have to test for
		// the precence of a specific property
		if('WebkitOpacity' in someScript.style) return 'Webkit';
		if('KhtmlOpacity' in someScript.style) return 'Khtml';

		return 'unknow';

	};	//	browserVendor();

	self.setBrowserVendor = function(){
		$('html').addClass(browserVendor()+'vendor-');
	};	//	setBrowserVendor();

	self.mobileDevice = function(){
		return !!(navigator.userAgent.toLowerCase().match(/(iPhone|iPod|blackberry|android 0.5|htc|lg|midp|mmp|mobile|nokia|opera mini|palm|pocket|psp|sgh|smartphone|symbian|treo mini|Playstation Portable|SonyEricsson|Samsung|MobileExplorer|PalmSource|Benq|Windows Phone|Windows Mobile|IEMobile|Windows CE|Nintendo Wii)/i));
	};	//	mobileDevice();

	self.smartmail = function(selector){
		/**
		 * es. <a class="smartmail" data-name="info" data-dom="wipitalia.it" href="javascript:void(0);">info @ wipitalia.it</a>
		 */
		$(selector).on('click', function(e) {
			var name = $(this).data('name');
			var domain = $(this).data('dom');
			var url = 'mailto:'+name+'@'+domain;
			window.open(url,"_top");
		});
	}

	self.randomNum = function(range) {
		if (range==null)console.error("need var range");
		var number = 1 + Math.floor(Math.random() * range);
		return number;
	}	// randomNum(range)

	self.wipTabber = function(opt){

		if (opt.wrap==null)console.error("wipTabber needs a global wrapper ID");
		if (opt.nav==null)console.error("wipTabber needs a navigation UL class");
		if (opt.item==null)console.error("wipTabber needs a tab item class for contents");

		var item = '#'+opt.wrap+' '+opt.item;
		var nav = '#'+opt.wrap+' '+opt.nav;



		if($(item + '.active').size()==0) {
			$(nav + ' > li:eq(0)').addClass('active');
			$(item + ':eq(0)').addClass('active');
		}
		$(item + ':not(.active)').addClass('hidden');

		$(nav + ' > li:not(.disable)').on('click',function(){
			var btn = $(this);
			$(item).parent().addClass('js-loading');
			var indice = $(nav + ' > li').index(btn);
			$(nav + ' > li').removeClass('active');
			btn.addClass('active');
			$(item).addClass('hidden').removeClass('active');
			$(item + ':eq('+indice+')').removeClass('hidden').addClass('active');
			$(item).parent().removeClass('js-loading');
			$(win).trigger('resize');
			if($(nav + ' a').length > 0)return false;
		});
	}	// wipTabber;


	self.requestFullScreen = function(el){
		// Supports most browsers and their versions.
		//https://developer.mozilla.org/en-US/docs/Web/Guide/API/DOM/Using_full_screen_mode?redirectlocale=en-US&redirectslug=Web%2FGuide%2FDOM%2FUsing_full_screen_mode
		var rfs = // for newer Webkit and Firefox
		el.requestFullScreen
		|| el.webkitRequestFullScreen
		|| el.mozRequestFullScreen
		|| el.msRequestFullScreen
		;

		if(typeof rfs!="undefined" && rfs){
			rfs.call(el);
		} else if(typeof win.ActiveXObject!="undefined"){
			// for Internet Explorer
			var wscript = new ActiveXObject("WScript.Shell");
			if (wscript!=null) {
				wscript.SendKeys("{F11}");
			}
		}
	}	// requestFullScreen();

	self.cancelFullScreen = function(){
		//wdbg.log('wipwrp.utils:  cancelFullScreen');
		// Exit full screen.
		var el = doc
			,rfs = 	// for newer Webkit and Firefox
			el.exitFullscreen
			|| el.msExitFullscreen
			|| el.mozCancelFullScreen
			|| el.webkitExitFullscreen
			;
			if(typeof rfs!="undefined" && rfs){
				rfs.call(el);
			} else if(typeof win.ActiveXObject!="undefined"){
			// for Internet Explorer
			var wscript = new ActiveXObject("WScript.Shell");
			if (wscript!=null) {
				wscript.SendKeys("{F11}");
			}
		}
	}	// cancelFullScreen(element);


	self.isScrolledIntoView = function(obj, tgtpoint) {
		(tgtpoint != 'undefined' && tgtpoint != '') ? keypoint='top':keypoint = tgtpoint;
		var docViewTop = ($(window).scrollTop() + $(window).height()) - 3*($(window).height()/4);
		var docViewBottom =  ($(window).scrollTop() + $(window).height()) - ($(window).height()/4);
		if(keypoint=='top') {
			var objTop = $(obj).offset().top;
			var result = ((objTop <= docViewBottom) && (objTop >= docViewTop));
		} else {
			var objCenter = ($(obj).offset().top + $(obj).outerHeight()) - ($(obj).outerHeight()/2);
			var result = ((objCenter <= docViewBottom) && (objCenter >= docViewTop));
		}
		return result;
	}


	self.toolTipLight = function(tipperSelector,messageSelector){
		var distance = 12;
		var txt = '';

		jQuery(tipperSelector).hover(
			function(){
				//txt = jQuery(this).attr(messageSelector);
				txt = jQuery(this).parent('label').find('.tt-tip').html();
				jQuery('<div />').attr('class','tip_content').appendTo('body');
				jQuery('.tip_content').html(txt).addClass('cssdirs');
			},
			function(){
				jQuery('.tip_content').remove();
			}
		);


		jQuery(document).mousemove(function(e){
			var obj = jQuery('.tip_content');
			var top = e.pageY - distance - jQuery('.tip_content').outerHeight();
			var pos = {
				top: top < 0 ? 0 : top,
				left: e.pageX + jQuery('.tip_content').width() /4
			}
			obj.css(pos);
		});
	}



	self.checkLikeRadio = function(){
		if ($('.check-radiolike').length > 0){
			$('.check-radiolike').on('change',function() {
				$('.check-radiolike[name="'+this.name+'"]').filter(':checked').not(this).removeAttr('checked');
			});
		}
	}	// _checkLikeRadio();

	self.tooltip = function(){
		var tooltip =  {}
		tooltip.obj ,
		target  = false,
		tooltip = false,
		title   = false;

		$( '[rel~=tooltip]' ).on( 'mouseenter', function() {
			target  = $( this );
			tip     = target.attr( 'title' );
			tooltip = $( '<div id="tooltip"></div>' );

			if( !tip || tip == '' )
				return false;

			target.removeAttr( 'title' );
			tooltip.css( 'opacity', 0 )
			.html( tip )
			.appendTo( 'body' );

			var init_tooltip = function()     {
				if( $( win ).width() < tooltip.outerWidth() * 1.5 )
					tooltip.css( 'max-width', $( win ).width() / 2 );
				else
					tooltip.css( 'max-width', 340 );

				var pos_left = target.offset().left + ( target.outerWidth() / 2 ) - ( tooltip.outerWidth() / 2 ),
				pos_top  = target.offset().top - tooltip.outerHeight() - 20;

				if( pos_left < 0 )         {
					pos_left = target.offset().left + target.outerWidth() / 2 - 20;
					tooltip.addClass( 'left' );
				}
				else
					tooltip.removeClass( 'left' );

				if( pos_left + tooltip.outerWidth() > $( win ).width() )         {
					pos_left = target.offset().left - tooltip.outerWidth() + target.outerWidth() / 2 + 20;
					tooltip.addClass( 'right' );
				}
				else
					tooltip.removeClass( 'right' );

				if( pos_top < 0 )         {
					var pos_top  = target.offset().top + target.outerHeight();
					tooltip.addClass( 'top' );
				}
				else
					tooltip.removeClass( 'top' );

				tooltip.css( { left: pos_left, top: pos_top } )
				.animate( { top: '+=10', opacity: 1 }, 50 );
			};

			init_tooltip();
			$(win).resize( init_tooltip );

			var remove_tooltip = function() {
				tooltip.animate( { top: '-=10', opacity: 0 }, 50, function()
				{
					$( this ).remove();
				});
				target.attr( 'title', tip );
			};

			target.on( 'mouseleave', remove_tooltip );
			tooltip.on( 'click', remove_tooltip );
		});
	}	// tooltip();



	/* ----------------- public methods ---------------*/

	return self;


})(jQuery,Modernizr, document, window);
