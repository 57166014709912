
/* ------------------------------------------------------------
	DEFAULT PAGE CLASS SINTAX (better to have a new file)
------------------------------------------------------------ */

wipwrp.common = (function($) {

	var self = {}


	self.init= function(){
		_cookiebar();
		_menuMobile();
		_backToTop();
		_verticalNav();
        _accordion();

		wipwrp.utils.wipTabber({
			'wrap': 'investimento'
			,'nav': '.tab-link'
			,'item': '.tab-content'
		});
		_gallery();
		_popUpvideo();
		_tooltip({
			wrapper: '.list-events .item-event',
			trigger: '.trigger-info',
			tooltip: '.wrapper-info'
		});
		wipwrp.utils.smartmail('.smartmail');
	}

	function _cookiebar(){
		$('.cookie-bar').cookieBar({
			closeButton : '.close-btn'
		});
	}

	function _menuMobile(){
			$('.btn-menu').on('click',function(){
					$(this).toggleClass('active');
					if($(this).hasClass('active')){
						$('#masthead').addClass('open');
						$('.btn-menu .icon-menu').hide();
						$('.btn-menu .icon-delete').show();
					}else{
						$('#masthead').removeClass('open');
						$('.btn-menu .icon-delete').hide();
						$('.btn-menu .icon-menu').show();
				 }
			});
	}



	function _backToTop(){

		$('.back-top').on( 'click', function(event) {
			event.preventDefault();
			$('body,html').animate({
				scrollTop: 0 ,
		 	}, 700);
		});
	}

	function _verticalNav(){

		var contentSections = $('.block-step'),
			navigationItems = $('#menu li .btn-nav');
			
		updateNavigation();
		$(window).on('scroll', function(){
			updateNavigation();
			var top = $(this).scrollTop();

			if (Modernizr.mq('only screen and (max-width: 769px)')) {

			}else{
				
				if( top > 70){
		    		$('#masthead').addClass('reduce');
				}else{
					$('#masthead').removeClass('reduce');
		    	}
			}
			
		});

		navigationItems.on('click', function(event){
	        event.preventDefault();
	        smoothScroll($(this.hash));
	        if (Modernizr.mq('only screen and (max-width: 769px)')) {
	        	$('.btn-menu').removeClass('active');
	        	$('#masthead').removeClass('open');
	        	$('.btn-menu .icon-delete').hide();
				$('.btn-menu .icon-menu').show();
			}
	    });

		function updateNavigation() {
			contentSections.each(function(){
				$this = $(this);
				var activeSection = $('#menu li a[href="#'+$this.attr('id')+'"]').data('number') - 1;
				if ( ( $this.offset().top - $(window).height()/2 < $(window).scrollTop() ) && ( $this.offset().top + $this.height() - $(window).height()/2 > $(window).scrollTop() ) ) {
					navigationItems.eq(activeSection).addClass('active');
				}else {
					navigationItems.eq(activeSection).removeClass('active');
				}
			});
		}

		function smoothScroll(target) {
			if (Modernizr.mq('only screen and (max-width: 769px)')) {
	        	$('body,html').animate(
		        	{'scrollTop':target.offset().top-85},
		        	600
		        );
				}else{
			
				$('body,html').animate(
		        	{'scrollTop':target.offset().top-110},
		        	600
		        );
			}
	        
		}
    	
	}

	function _gallery(){
			$('.gallery').slick({
				dots: true,
				autoplay: true,
				prevArrow: '<div class="box-btn prev"><div class="btn-arrow"><span class="icon icon-arrow-left"></span></div></div>',
				nextArrow: '<div class="box-btn next"><div class="btn-arrow"><span class="icon icon-arrow-right"></span></div></div>'
			});

	}

	function _popUpvideo(){
		
		if (Modernizr.mq('only screen and (max-width: 769px)')) {
			
			$('.fancybox-media').fancybox({
				padding     : 0,
				fitToView	: false,
				width		: '100%',
				height		: '50%',
				autoSize	: false,
				closeClick	: false,
				openEffect	: 'none',
				closeEffect	: 'none',
				prevEffect : 'none',
				nextEffect : 'none',
				helpers : {
					title:  null,
					media : {},
					overlay :{
						locked: false
					}
				}
			});

		}else{
			
			$('.fancybox-media').fancybox({
				padding     : 0,
				fitToView	: false,
				width		: '50%',
				height		: '50%',
				autoSize	: false,
				closeClick	: false,
				openEffect	: 'none',
				closeEffect	: 'none',
				prevEffect : 'none',
				nextEffect : 'none',
				helpers : {
					title:  null,
					media : {},
					overlay :{
						locked: false
					}
				}
			});	
		}


	}

	function _tooltip(opts) {
		var $wrapper = $(opts.wrapper);
		$wrapper.on('click', opts.trigger, function (e) {
			e.preventDefault();
			if (Modernizr.mq('only screen and (max-width: 769px)')) {
				$wrapper.find(opts.tooltip).not($(this).next(opts.tooltip)).slideUp();
				$(this).next(opts.tooltip).stop().slideToggle();
			} else {
				$wrapper.find(opts.tooltip).not($(this).next(opts.tooltip)).hide();
				$(this).next(opts.tooltip).toggle();
			}
		});
	}

    function _accordion() {
        var accItem = document.getElementsByClassName('accordion_item');
        var i;

        for (i = 0; i < accItem.length; i++) {
            accItem[i].onclick = function() {
                if( this.classList.contains("active")){
                  hideAll();
								}else{
                  hideAll();
                  this.classList.add("active");
								}
            }
        }
        function hideAll() {
            for (i = 0; i < accItem.length; i++) {
                accItem[i].classList.remove("active");
            }
        }
    }

	
	

	//  return public methods
	return self;

})(jQuery);
